import { useMount } from 'ahooks';
import { Button, message } from 'antd';
import { isNil } from 'lodash-es';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logtoClient } from '../../LogtoContainer';
import { getRedirectApp } from './getRedirectApp';
import style from './style.module.css';

const SignIn = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const app = searchParams.get('app');

	if (app === '' || app === 'null' || isNil(app)) {
		return <div className={style.container}>应用退出</div>;
	}

	useMount(async () => {
		localStorage.setItem('app', app);

		await logtoClient.signIn(getRedirectApp());
	});

	return <div className={style.container}>登陆中</div>;
};

export { SignIn };
