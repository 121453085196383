import { TrpcBase } from '@/utils/trpc';
import { useFormLocalState } from '../useFormLocalState';
import Button, { isString } from 'antd/es/button';
import { camelCase, isDate, isNil, isObject, isPlainObject } from 'lodash-es';
import { useState } from 'react';
import { Form, Input, Row, Table, Tabs, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { tableImportTemplate } from './tableImportTemplate';
import ExcelJS from 'exceljs';
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc.js';
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Shanghai');

export interface statsType {
	schemaName: string;
	tableName: string;
	totalSize: string;
	dataSize: string;
	indexSize: string;
	estimatedRowsCount: number;
	estimatedRows: string;
	indexToDataRatio: string;
	indexesCount: number;
	columnsCount: string;
}

export type tableColumnType = tableColumnItemType[];

export interface tableColumnItemType {
	columnName: string;
	dataType: string;
	isNullable: string;
	characterMaximumLength?: number;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	characterSetCatalog: any;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	columnDefault: any;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	comment: any;
}

export default function TableSchema() {
	const { database, schema, setTable, table } = useFormLocalState();
	const [pageNum, setPageNum] = useState(0);
	const [previewExcel, setPreviewExcel] = useState<any[]>([]);
	const tableStatsApi = TrpcBase.note.queryInfo.useQuery({
		opType: 'tables_stats',
		params: [],
	});
	const targetInfo = tableStatsApi.data?.rows as unknown as statsType[];

	const [form] = Form.useForm();
	const sql = Form.useWatch('sql', form);
	const tableSchemaApi = TrpcBase.note.queryInfo.useQuery({
		opType: 'table_schema',
		params: [schema, table],
	});

	const preSql = TrpcBase.note.preSql.useMutation({
		onSuccess: (data, { pageNum: a }) => {
			setPageNum(a ?? 0);
			form.setFieldsValue({
				sql: data,
			});
		},
	});

	const tableDataApi = TrpcBase.note.querySQL.useMutation();

	if (!targetInfo) {
		return <div>loading</div>;
	}

	const headerTitle =
		tableSchemaApi.data?.rows?.map((i) => {
			return {
				header: i.columnName,
				key: i.columnName,
				width: 10,
			} as Partial<ExcelJS.Column>;
		}) ?? [];

	return (
		<div className="col-span-4">
			<div className="flex flex-row gap-2">
				<Button
					size="small"
					onClick={async () => {
						await preSql.mutate({
							table: table as string,
							pageNum: 0,
						});

						setTimeout(async () => {
							await form.submit();
						}, 50);
					}}
				>
					select
				</Button>

				<Button
					size="small"
					onClick={async () => {
						form.setFieldsValue({
							sql: 'ALTER TABLE table_name RENAME TO new_table_name',
						});
					}}
				>
					rename
				</Button>

				<Button
					type="primary"
					size="small"
					htmlType="submit"
					onClick={async () => {
						form.submit();
					}}
				>
					confirm
				</Button>

				<Button
					size="small"
					onClick={async () => {
						tableImportTemplate(headerTitle, [], `${table}_import`);
					}}
				>
					import template download
				</Button>

				<Upload
					multiple={false}
					maxCount={1}
					showUploadList={false}
					beforeUpload={(file) => {
						return false;
					}}
					onChange={async (value) => {
						const currentFile = value?.file;

						const reader = new FileReader();

						// @ts-expect-error
						reader.readAsArrayBuffer(currentFile);
						const wb = new ExcelJS.Workbook();

						reader.onload = () => {
							const buffer = reader.result;
							if (!buffer) {
								throw new Error('no buffer');
							}

							// @ts-expect-error
							wb.xlsx.load(buffer).then((workbook) => {
								console.log(workbook, 'workbook instance');
								workbook.eachSheet((sheet, id) => {
									// @ts-expect-error
									const arr = [];
									sheet.eachRow((row, rowIndex) => {
										// console.log(row.values, rowIndex);
										arr.push(row.values ?? []);

										// @ts-expect-error
										setPreviewExcel(arr);
									});
								});
							});
						};
					}}
				>
					<Button size="small" icon={<UploadOutlined />}>
						import
					</Button>
				</Upload>
			</div>
			<Form
				className="py-2"
				form={form}
				onFinish={async (v) => {
					console.log(v);
					await tableDataApi.mutateAsync({ sql: sql });
				}}
			>
				<Form.Item name="sql">
					<TextArea placeholder="sql" rows={5} />
				</Form.Item>
			</Form>
			<div>
				<Tabs
					items={[
						{
							key: 'data',
							label: 'data',
							children: (
								<div>
									{tableDataApi.isError && tableDataApi.error.message}
									{tableDataApi.isSuccess && (
										<>
											<div className="flex flex-row">
												{['insertId', 'numAffectedRows', 'numChangedRows'].map(
													(k) => {
														return (
															// @ts-ignore
															!isNil(tableDataApi?.data?.[k]) && (
																<div>
																	{k}:
																	{
																		// @ts-ignore
																		tableDataApi?.data?.[k]?.toString()
																	}
																</div>
															)
														);
													},
												)}
												{tableDataApi.isSuccess &&
													tableDataApi?.data?.rows.length === 0 &&
													'query done'}
											</div>

											{tableDataApi.isSuccess &&
												tableDataApi?.data?.rows.length > 0 && (
													<>
														<Table
															style={{ height: '60dvh' }}
															columns={
																Object.keys(
																	tableDataApi?.data?.rows?.[0] ?? {},
																)?.map((tp) => {
																	return {
																		index: camelCase(tp),
																		title: tp,
																		width: 120,
																		sort: true,
																	};
																}) ?? []
															}
															dataSource={
																tableDataApi?.data?.rows?.map((o) => {
																	if (isObject(o)) {
																		for (const idx in o) {
																			// @ts-ignore
																			const v = o[idx];
																			if (
																				isObject(v) &&
																				Object.keys(v).length > 2
																			) {
																				// @ts-ignore
																				o[idx] = JSON.stringify(o);
																			}
																		}
																	}

																	return o;
																}) ?? []
															}
														/>
														<div className="flex flex-row items-center gap-2">
															<div className="text-lg"> 页数: {pageNum} </div>

															<Button
																onClick={async () => {
																	await preSql.mutate({
																		table: table as string,
																		pageNum: pageNum + 1,
																	});

																	await form.submit();
																}}
															>
																下一页
															</Button>
															<Button
																className={pageNum === 0 ? 'hidden' : ''}
																onClick={async () => {
																	await preSql.mutate({
																		table: table as string,
																		pageNum: pageNum - 1,
																	});

																	await form.submit();
																}}
															>
																上一页
															</Button>
														</div>
													</>
												)}
										</>
									)}
								</div>
							),
						},
						{
							key: 'INSERT',
							label: 'INSERT',
							children: (
								<div className="break-all">
									{`INSERT INTO ${table}(${tableSchemaApi.data?.rows.map(
										(i) => i.columnName,
									)}) VALUES ${previewExcel
										.slice(1)
										.map((i) => {
											return `(${i
												.slice(1)
												.map((a: any) => {
													if (isDate(a)) {
														console.log(i[6] === '¥16.00' && i);
														return `'${dayjs
															.utc(a)
															.format('YYYY-MM-DD hh:mm:ss')}'`;
													}
													if (isString(a) && a.includes('¥')) {
														return a.slice(1);
													}

													return `'${a}'`;
												})
												.join(', ')})`;
										})
										.join(', ')}`}
								</div>
							),
						},
						{
							key: 'UPDATE',
							label: 'UPDATE',
							children: (
								<div className="break-all">
									{`
                  ${previewExcel
										.slice(1)
										.map((i) => {
											return `
                      UPDATE ${table} SET exchange_hour=${`'${dayjs
												.utc(i[1])
												.format(
													'YYYY-MM-DD hh:mm:ss',
												)}'`}  WHERE transaction_order_number='${i[9]}'`;
										})
										.join('; ')}
                  
                 `}
								</div>
							),
						},
						{
							key: 'table_struct',
							label: 'table struct',
							children: (
								<div>
									{tableSchemaApi?.data?.rows?.map((i) => {
										return (
											<div>
												{Object.entries(i).map((a) => (
													<div>{`${a[0]}:${a[1]}`}</div>
												))}
											</div>
										);
									})}
								</div>
							),
						},
						{
							key: 'tableCols',
							label: 'table cols',
							children: (
								<>
									{JSON.stringify(
										headerTitle.map((i) => {
											return {
												key: camelCase(i.header),
												dataIndex: camelCase(i.header),
												title: camelCase(i.header),
											};
										}),
									)}
								</>
							),
						},
					]}
				/>
			</div>
		</div>
	);
}
