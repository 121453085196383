import { getActionCode } from './getActionCode';
import { getFirstUpper } from './getFirstUpper';
import type { formDataType, resType } from './useFormLocalState';

export function getSource(res: resType[], data: formDataType) {
	const { tableName, keyField, filePath } = data;
	const UpperCaseTableName = getFirstUpper(tableName);
	const mPath = `${filePath.replaceAll('\\', '/')}/${tableName}`;

	return {
		path: mPath,
		files: [
			...res.map((i) => {
				const action = i.action;
				const UpperAction = getFirstUpper(action);

				return {
					action: action,
					filename: `${mPath}/${action}${UpperCaseTableName}.tsx`,
					content: `
      import { z } from 'zod';
      import { db } from 'blog/type-file';
      
      export const ${UpperAction}${UpperCaseTableName}Params = z.object({
        uuid: z.string(),
      });
      
      export type type${UpperAction}${UpperCaseTableName}Params = z.infer<typeof ${UpperAction}${UpperCaseTableName}Params>;
      
      export async function ${UpperAction}${UpperCaseTableName}Item({ ${keyField} }: type${UpperAction}${UpperCaseTableName}Params) {
        return ${getActionCode(action, data)};
      }
                          `,
				};
			}),

			{
				action: 'router',
				filename: `${mPath}/${UpperCaseTableName}Service.tsx`,
				content: `import { protectedProcedure } from 'blog/plugins/trpc';
        ${res
					.map((i) => {
						const action = i.action;
						const UpperAction = getFirstUpper(action);
						return `import { ${UpperAction}${UpperCaseTableName}Item, ${UpperAction}${UpperCaseTableName}Params } from './${action}${UpperCaseTableName}';
          `;
					})
					.join('')}

        export const ${UpperCaseTableName}Service = {
          ${res.map((i) => {
						const action = i.action;
						const UpperAction = getFirstUpper(action);
						return `${UpperAction}${UpperCaseTableName}: protectedProcedure.input(${UpperAction}${UpperCaseTableName}Params).query(async ({ ctx, input }) => {
              return await ${UpperAction}${UpperCaseTableName}Item(input, ctx.user);
            })`;
					})}
        }
        
        `,
			},
		],
	};
}
