import { useQuery } from '@tanstack/react-query';
import { Button, Card, Form, Input, InputNumber, message, Popconfirm, Table } from 'antd';
import { WOOD_CODE } from 'blog/wood-code';
import { isNil } from 'lodash-es';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { honoClient } from './honoClient';
import { useEffect, useState } from 'react';
import { getStockTableSetting } from './component/getStockTableSetting';
import { useGlobalManagementStore } from '../apartment/myInfo/useGlobalManagement';
import { roundFix2 } from './roundFix2';

export default function PartReturnedPurchase() {
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const { globalManagement, setGlobalManagement } = useGlobalManagementStore();
    const uuid = searchParams.get('uuid');
    const [rows, setRows] = useState<Key[]>([]);
    const navigate = useNavigate();
    const detailQuery = useQuery({
        queryKey: ['transactionDetail', uuid],
        queryFn: async () => {
            const res = await honoClient.transactionDetail.$post({
                json: { uuid: uuid ?? '' },
            });

            const queryRes = await res.json();

            return queryRes;
        },
        enabled: !isNil(uuid),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (detailQuery.isSuccess) {
            const initialValue = {
                uuid: uuid,
                ...detailQuery.data,
            };
            console.log('initialValue', initialValue);

            form.setFieldsValue(initialValue);
        }
    }, [detailQuery.isSuccess, detailQuery, form, uuid]);

    return <Form
        className='p-5 bg-white'
        form={form}
        onFinish={async (v) => {
        }}>
        <h3>退货操作台</h3>
        <Form.Item name="orderId" label="orderId">
            <Input disabled />
        </Form.Item>

        <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
                const mStock = getFieldValue('stock');
                const isOldSystem = mStock?.some((i) => isNil(i.uuid)) ?? false;

                return (
                    <>
                        <Table
                            size="small"
                            rowSelection={{
                                selectedRowKeys: rows,
                                onChange: (keys) => {
                                    setRows(keys);
                                },
                            }}
                            dataSource={mStock}
                            {...getStockTableSetting([], {
                                cols: [],
                                isEditUuid: [],
                                havingCost: globalManagement
                            })}
                            rowKey={isOldSystem ? 'id' : 'uuid'}
                            pagination={false}
                        />

                        <div className='my-3'>总计 {mStock?.length}</div>
                    </>
                );
            }}
        </Form.Item>

        <div className='flex flex-row gap-2'>

            <Button
                onClick={async () => {
                    const mStock = form.getFieldValue('stock');
                    if (mStock.length === 1) {
                        message.error('货品只有一个, 无法进行部分退货');
                        return;
                    }
                    if (rows.length === mStock.length) {
                        message.error('不可选中全部进行退货');
                        return;
                    }

                    const deliveryDetail = {
                        uuid: uuid,
                        returnOfStocks: rows,
                    };
                    console.log(`选中内容`, deliveryDetail);

                    const returnedRes = await honoClient.partReturnedPurchase.$post({
                        json: deliveryDetail,
                    });

                    const returnedOpsRes = await returnedRes.json();

                    if (returnedOpsRes.code === WOOD_CODE.OK) {
                        message.success('OK');
                        navigate('/wood/transaction');
                        return;
                    }

                    message.error(returnedOpsRes.message);
                }}
            >
                部分退货
            </Button>

            <Popconfirm
                title="退货确认"
                description="是否执行"
                onConfirm={async () => {
                    const res = await (
                        await honoClient.returnedPurchase.$post({
                            json: { uuid: uuid },
                        })
                    ).json();

                    if (res.code === 0) {
                        message.success('退货成功');
                        navigate("/wood/transaction")
                    }
                }}
                okText="Yes"
                cancelText="No"
            >
                {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
                <Button>全部退货</Button>
            </Popconfirm>
        </div>
    </Form>

}